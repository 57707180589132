import {Component} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default class ErrorPassword extends Component {
    render() {
        return <div className="error-page">
            <Header />
            <section>
                <div className="jumbotron jumbotron-fluid py-5">
                    <div className="container text-center py-5">
                        <h2>The password link is expired.</h2>
                        <h4>You may use your TruGrid application to reset your password. Or please open a <a href="https://ticket.summithosting.com/hc/en-us" target="_blank" rel="noreferrer">support ticket to receive assistance with this issue.</a></h4>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>;
    }
}