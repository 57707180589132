import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Home from "./pages/Home";
import Complete from "./pages/Complete";
import InviteUsers from "./pages/InviteUsers";
import NoAccess from "./pages/NoAccess";
import InviteNotFound from "./pages/NotFound";
import Error from "./pages/Error";
import ErrorPassword from "./pages/ErrorPassword";
import ResetPassword from "./pages/ResetPassword.js";
import ResetComplete from "./pages/ResetComplete";

function App() {
  return <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/invite/:domain/:inviteUuid" component={InviteUsers} />
      <Route path="/complete" component={Complete} />
      <Route path="/noaccess" component={NoAccess} />
      <Route path="/invitenotfound" component={InviteNotFound} />
      <Route path="/error" component={Error} />
      <Route path="/errorpassword" component={ErrorPassword} />
      <Route path="/reset/:token" component={ResetPassword} />
      <Route path="/resetcomplete" component={ResetComplete} />
    </Switch>
  </Router>;
}

export default App;
