import {Component} from "react";
import Header from "../components/Header";
import axios from "axios";
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import Footer from "../components/Footer";

export default class InviteUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            organization: {},
            errorMessage: ''
        };

        this.inviteUsers = this.inviteUsers.bind(this);
    };

    async componentDidMount() {
        const inviteUuid = this.props.match.params.inviteUuid;
        const domain = this.props.match.params.domain;

        const getResponse = async () => {
            try {
                return await axios.get(`/api/organizationLookup/${domain}/${inviteUuid}`);
            } catch (err) {
                if(err.response.status === 403) {
                    this.props.history.push('/noaccess');
                }
                else if(err.response.status === 404) {
                    this.props.history.push('/invitenotfound');
                }
                else {
                    const errorState = {
                        message: err.response.data
                    }

                    this.props.history.push('/error', errorState);
                }
            }
        };

        const response = await getResponse();

        if(!response) {
            return;
        }

        this.initialValues = {
            users: [...Array(response.data.available_seats)].map(n => ({
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                mfa_enabled: true
            }))
        };

        const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

        this.validationSchema = Yup.object().shape({
            users: Yup.array().of(
                Yup.object().shape({
                    first_name: Yup.string().when('email', {
                        is: value => !!value,
                        then: Yup.string().required("This is a required field.").max(40, "Max 40 characters")
                    }),
                    last_name: Yup.string().when('first_name', {
                        is: value => !!value,
                        then: Yup.string().required("This is a required field.").max(40, "Max 40 characters.")
                    }),
                    email: Yup.string().when('last_name', {
                        is: value => !!value,
                        then: Yup.string().email("This must be an e-mail address.").required("This is a required field.")
                    }),
                    phone: Yup.string().when(['mfa_enabled', 'email'], {
                        is: (mfa_enabled, email) => mfa_enabled && !!email,
                        then: Yup.string().matches(phoneRegExp, "This must be a valid phone number.").required("This is required when MFA is enabled.")
                    })
                }, [['first_name', 'last_name'], ['first_name', 'email'], ['last_name', 'email']])
            )
        })

        this.setState({loading: false, organization: response.data});
    }

    async inviteUsers(values) {
        const inviteUuid = this.props.match.params.inviteUuid;
        const domain = this.props.match.params.domain;
        const users = values.users.filter(user => !!user.email);
        this.setState({processing: true});
        try {
            await axios.post(`/api/provisionUsers/${domain}/${inviteUuid}`, {users: users});
            this.props.history.push('/complete');
        } catch (err) {
            if(err.response.status === 400) {
                this.setState({processing: false, errorMessage: err.response.data});
            }
            else if(err.response.status === 403) {
                this.props.history.push('/noaccess');
            }
            else if(err.response.status === 404) {
                this.props.history.push('/invitenotfound');
            }
            else {
                const errorState = {
                    message: err.response.data
                }

                this.props.history.push('/error', errorState);
            }
        }
    }

    render() {
        if(this.state.loading) {
            return (
                <div className="invite">
                    <Header />
                    <main className="loading-container">
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border text-success spinner-custom" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </main>
                </div>
            );
        }

        const users = this.state.organization.users;

        const sortedUserKeys = Object.keys(users).sort((emailA, emailB) => {
            const lastNameA = users[emailA].last_name.toLowerCase();
            const lastNameB = users[emailB].last_name.toLowerCase();

            if (lastNameA > lastNameB) return 1;
            if (lastNameA < lastNameB) return -1;
            return 0;
        });

        const existingUserRows = sortedUserKeys.map((e, i) => {
            return (<div className="row invite-form-row" key={"existing"+i}>
                <div className="col">
                    <input type="text" className="form-control" value={users[e].first_name} disabled={true} />
                </div>
                <div className="col">
                    <input type="text" className="form-control" value={users[e].last_name} disabled={true} />
                </div>
                <div className="col">
                    <input type="text" className="form-control" value={e} disabled={true} />
                </div>
                <div className="col">
                    <input type="text" className="form-control" value={users[e].phone} disabled={true} />
                </div>
                <div className="col mfa-box">
                    <input type="checkbox" className="form-control" checked={users[e].mfa_enabled} disabled={true} />
                </div>
            </div>)
        });

        const accountManager = this.state.organization.external_account_id.startsWith('ICA-') ? 'Intuit' : 'Summit Hosting';
        let instructions;
        if (this.state.organization.available_seats === 0) {
            instructions = (<div>
                <h2>You've invited all of your users.</h2>
                <h4>If you would like to purchase more user logins, please contact your {accountManager} account manager.</h4>
            </div>);
        } else if (this.state.processing) {
            instructions = (<div className="spinner-border text-success spinner-custom" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>);
        } else if (this.state.errorMessage) {
            instructions = (<div>
                <h2>{this.state.errorMessage}</h2>
            </div>);
        } else {
            instructions = (<div>
                <h2>Please invite up to {this.state.organization.available_seats} users.</h2>
                <h4>You may come back to this site any time to invite more users.</h4>
                <h4>If you would like to purchase more user logins, please contact your {accountManager} account manager.</h4>
            </div>);
        }

        return (
            <div className="invite">
                <Header />
                <main className="container">
                    <div className="jumbotron jumbotron-fluid py-5 invite-jumbo">
                        <div className="container text-center">
                            {instructions}
                            <hr className="invite-form-header-spacer" />
                            <div className="row">
                                <div className="col text-left invite-form-header">First name</div>
                                <div className="col text-left invite-form-header">Last name</div>
                                <div className="col text-left invite-form-header">Email</div>
                                <div className="col text-left invite-form-header">Phone</div>
                                <div className="col text-left mfa-box"><span className="mfa-box-text">Use MFA</span></div>
                            </div>
                            <Formik initialValues={this.initialValues} validationSchema={this.validationSchema}
                                    onSubmit={this.inviteUsers}
                                render={({values, errors}) => {
                                    return <Form>
                                        {existingUserRows}
                                        <FieldArray name="users">
                                            {({ insert, remove, push }) => (
                                                <div>
                                                    {values.users.length > 0 && values.users.map((user, index) => (
                                                        <div className="row invite-form-row" key={index}>
                                                            <div className="col">
                                                                <Field name={`users.${index}.first_name`} type="text" className="form-control" placeholder="First Name"/>
                                                                <ErrorMessage name={`users.${index}.first_name`} component="div" className="field-error"/>
                                                            </div>
                                                            <div className="col">
                                                                <Field name={`users.${index}.last_name`} type="text" className="form-control" placeholder="Last Name"/>
                                                                <ErrorMessage name={`users.${index}.last_name`} component="div" className="field-error"/>
                                                            </div>
                                                            <div className="col">
                                                                <Field name={`users.${index}.email`} type="email" className="form-control" placeholder="email@example.com"/>
                                                                <ErrorMessage name={`users.${index}.email`} component="div" className="field-error"/>
                                                            </div>
                                                            <div className="col">
                                                                <Field name={`users.${index}.phone`} type="text" className="form-control" placeholder="555-555-5555"/>
                                                                <ErrorMessage name={`users.${index}.phone`} component="div" className="field-error"/>
                                                            </div>
                                                            <div className="col mfa-box">
                                                                <Field name={`users.${index}.mfa_enabled`} type="checkbox" className="form-control"/>
                                                                <ErrorMessage name={`users.${index}.mfa_enabled`} component="div" className="field-error"/>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </FieldArray>
                                        <div className="form-group row invite-submit-row">
                                            <div className="col-12">
                                                <button type="submit" className="btn btn-primary float-right" disabled={this.state.organization.available_seats === 0}>Submit</button>
                                            </div>
                                        </div>
                                    </Form>;
                                }}>
                            </Formik>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        );
    }
}
