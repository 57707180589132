import {Component} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default class ResetComplete extends Component {
    render() {
        return <div className="complete">
            <Header />
            <section>
                <div className="jumbotron jumbotron-fluid py-5">
                    <div className="container text-center py-5">
                        <h2>Your password has been set. Please allow 5 minutes for the update to take effect.</h2>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>;
    }
}