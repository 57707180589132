import {Component} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(Yup);

export default class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };

        this.setPassword = this.setPassword.bind(this);
    };

    async componentDidMount() {
        const token = this.props.match.params.token;

        const getResponse = async () => {
            try {
                return await axios.post('/api/checkToken/', {token: token});
            } catch (err) {
                if(err.response.status === 403) {
                    this.props.history.push('/noaccess');
                }
                else if(err.response.status === 404) {
                    this.props.history.push('/errorpassword');
                }
                else {
                    const errorState = {
                        message: err.response.data
                    }

                    this.props.history.push('/errorpassword', errorState);
                }
            }
        };

        const response = await getResponse();

        if(!response) {
            return;
        }

        this.initialValues = {
            password: '',
            passwordConfirm: ''
        };

        this.validationSchema = Yup.object().shape({
            password: Yup.string().password().min(10).minSymbols(0).required('Password is required.'),
            passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Please confirm the password.')
        });

        this.setState({loading: false });
    }

    async setPassword(values) {
        const token = this.props.match.params.token;
        const password = values.password;

        this.setState({loading: true});

        try {
            await axios.post(`/api/resetPassword/`, {token: token, password: password});
            this.props.history.push('/resetcomplete');
        } catch (err) {
            this.props.history.push('/errorpassword');
        }
    }

    render() {
        if(this.state.loading) {
            return (
                <div className="invite">
                    <Header />
                    <main className="loading-container">
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border text-success spinner-custom" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </main>
                </div>
            );
        }

        return (
            <div className="invite">
                <Header />
                <main className="container">
                    <div className="jumbotron jumbotron-fluid py-5">
                        <div className="container text-center py-5">
                            <h5>Please enter a password at least 10 characters long with at least 1 of each lowercase, uppercase and numbers.</h5>
                            <h5>The password also cannot contain your e-mail address or any part of your name.</h5>
                            <Formik initialValues={this.initialValues} validationSchema={this.validationSchema}
                                    onSubmit={this.setPassword}
                                    render={({values, errors}) => {
                                        return <Form>
                                            <div className="row">
                                                <div className="col-6 mx-auto pw-box">
                                                    <Field type="password" name="password" className="form-control" placeholder="Password"/>
                                                    <ErrorMessage name="password" component="div" className="field-error"/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 mx-auto pw-box">
                                                    <Field type="password" name="passwordConfirm" className="form-control" placeholder="Password"/>
                                                    <ErrorMessage name="passwordConfirm" component="div" className="field-error"/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-12">
                                                    <button type="submit" className="btn btn-primary float-right">Submit</button>
                                                </div>
                                            </div>
                                        </Form>;
                                    }}>
                            </Formik>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        );
    }
}
